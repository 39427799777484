@font-face {
  font-family: "dotsfont";
  src: url("../assets/fonts/dotsfont.eot");
  src:
    url("../assets/fonts/dotsfont.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/dotsfont.woff") format("woff"),
    url("../assets/fonts/dotsfont.ttf") format("truetype"),
    url("../assets/fonts/dotsfont.svg#dotsfontregular") format("svg");
}
[conceal]:not(:active):not(:focus) {
  font-family: "dotsfont";
  font-size: 20px;
}
.conceal {
  -webkit-text-security: disc;
  font-size: 20px;
}
