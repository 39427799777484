@use "_agcolours" as colours;
@font-face {
  src: url("../assets/fonts/Lato-Thin.ttf") format("truetype");
  font-family: "Lato-Thin";
  font-weight: 100;
  font-style: normal;
}
@font-face {
  src: url("../assets/fonts/Lato-ThinItalic.ttf") format("truetype");
  font-family: "Lato-Thin";
  font-weight: 100;
  font-style: italic;
}
@font-face {
  src: url("../assets/fonts/Lato-Light.ttf") format("truetype");
  font-family: "Lato-Light";
  font-weight: 300;
  font-style: normal;
}
@font-face {
  src: url("../assets/fonts/Lato-LightItalic.ttf") format("truetype");
  font-family: "Lato-Light";
  font-weight: 300;
  font-style: italic;
}
@font-face {
  src: url("../assets/fonts/Lato-Regular.ttf") format("truetype");
  font-family: "Lato-Regular";
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url("../assets/fonts/Lato-Italic.ttf") format("truetype");
  font-family: "Lato-Regular";
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url("../assets/fonts/Lato-Bold.ttf") format("truetype");
  font-family: "Lato-Bold";
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url("../assets/fonts/Lato-BoldItalic.ttf") format("truetype");
  font-family: "Lato-Bold";
  font-weight: 700;
  font-style: italic;
}
@font-face {
  src: url("../assets/fonts/Lato-Black.ttf") format("truetype");
  font-family: "Lato-Black";
  font-weight: 900;
  font-style: normal;
}
@font-face {
  src: url("../assets/fonts/Lato-BlackItalic.ttf") format("truetype");
  font-family: "Lato-Black";
  font-weight: 900;
  font-style: italic;
}

/* Poppins */
@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-LightItalic";
  src: url("../assets/fonts/Poppins-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("../assets/fonts/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("../assets/fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("../assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  src: url("../assets/fonts/OCRB Regular.ttf") format("truetype");
  font-family: "OCR-B Regular";
}

@font-face {
  src: url("../assets/fonts/OCRB Medium.ttf") format("truetype");
  font-family: "OCR-B Medium";
}

hr {
  margin: 24px 0;
  border-top: 1px solid colours.$ag-colour-lightgrey;
}

h1 {
  font-family: "Poppins-Regular";
  font-size: 24px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h1-medium {
  font-family: "Poppins-Medium";
  font-size: 24px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h1-semibold {
  font-family: "Poppins-SemiBold";
  font-size: 24px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h1-bold {
  font-family: "Poppins-Bold";
  font-size: 24px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}

h2 {
  font-family: "Poppins-Regular";
  font-size: 20px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h2-medium {
  font-family: "Poppins-Medium";
  font-size: 20px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h2-semibold {
  font-family: "Poppins-SemiBold";
  font-size: 20px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h2-bold {
  font-family: "Poppins-Bold";
  font-size: 20px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}

h3 {
  font-family: "Poppins-Regular";
  font-size: 18px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h3-medium {
  font-family: "Poppins-Medium";
  font-size: 18px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h3-semibold {
  font-family: "Poppins-SemiBold";
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h3-bold {
  font-family: "Poppins-Bold";
  font-size: 18px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
h4 {
  font-family: "Poppins-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h4-medium {
  font-family: "Poppins-Medium";
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h4-semibold {
  font-family: "Poppins-SemiBold";
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.h4-bold {
  font-family: "Poppins-Bold";
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
p,
b,
body,
html {
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.p-medium,
.b-medium,
.body-medium,
.html-medium {
  font-family: "Poppins-Medium";
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
.p-semibold,
.b-semibold,
.body-semibold,
.html-semibold {
  font-family: "Poppins-SemiBold";
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  background-color: transparent;
  color: colours.$ag-colour-dark;
  margin: 0;
}
.p-bold,
.b-bold,
.body-bold,
.html-bold {
  font-family: "Poppins-Bold";
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-dark;
}
a {
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  text-decoration-line: none;
  margin: 0;
  background-color: transparent;
  color: colours.$ag-colour-purple;
}
a:hover {
  color: colours.$ag-colour-purple !important;
}
