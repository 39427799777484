/* Loading indicator */

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1.5em 0 0;
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation-fill-mode: both;
  animation: load7 1s infinite ease-in-out;
}

.loader {
  color: #685e7a;
  font-size: 12px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  bottom: 10px;
  left: 20px;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -2em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 2em;
}
/* Loading indicator end */
