// @include mat-core();
@import "@angular/material/theming";
@import "./styles/dotsfont.scss";
@import "./styles/loader.scss";
// // aeroguest styleguide and theme
@import "./styles/agtheme";
@import "./styles/typography";
@import "./theme.scss";
@import "~quill/dist/quill.bubble.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
  $font-family: "inherit",
);

// @include mat-base-typography($custom-typography);

// // Override typography for a specific Angular Material components.
// @include mat-checkbox-typography($custom-typography);

// // Override typography for all Angular Material, including mat-base-typography and all components.
// @include angular-material-typography($custom-typography);

// @include mat-core($custom-typography);

body {
  /* Override Bootstrap reboot styles */
  margin: initial; /* Reset margin */
  font-family: inherit; /* Reset font-family */
  font-size: 100%; /* Reset font-size */
  font-weight: normal; /* Reset font-weight */
  line-height: normal; /* Reset line-height */
  color: inherit; /* Reset color */
  text-align: initial; /* Reset text-align */
  background-color: initial; /* Reset background-color */
}

//Cursor
body.inheritCursors * {
  cursor: inherit !important;
}

.ng2-pdf-viewer-container {
  overflow: hidden !important;
}

//flex-layout replacement
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
}
.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.flex-space-around {
  justify-content: space-around;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-space-evenly {
  justify-content: space-evenly;
}
.flex-align-start {
  align-items: flex-start;
  align-content: flex-start;
}
.flex-align-center {
  align-items: center;
  align-content: center;
}
.flex-align-end {
  align-content: flex-end;
  align-items: flex-end;
}
.flex-align-space-around {
  align-items: space-around;
  align-content: space-around;
}
.flex-align-space-between {
  align-items: space-between;
  align-content: space-between;
}
.flex-align-space-between {
  align-items: baseline;
  align-content: stretch;
}
.flex-align-strech-column {
  max-width: 100%;
}
.flex-align-stretch-row {
  max-height: 100%;
}
.hide-lt-xl {
  display: flex;
}
.hide-lt-lg {
  display: flex;
}
.hide-lt-md {
  display: flex;
}

@media (max-width: 1919px) {
  .hide-lt-xl {
    display: none;
  }
}
@media (max-width: 1280px) {
  .hide-lt-lg {
    display: none;
  }
}
@media (max-width: 960px) {
  .hide-lt-md {
    display: none;
  }
}
@media (max-width: 600px) {
  .hide-lt-sm {
    display: none;
  }
}

//----------------------

::-moz-selection {
  color: $ag-colour-dark;
  background: $ag-colour-lightpurple;
}
::selection {
  color: $ag-colour-dark;
  background: $ag-colour-lightpurple;
}
// --------------------------------------------
// AG Construction site
// --------------------------------------------

img {
  max-width: 100%;
}

img.rounded-borders {
  border-radius: 20px;
}

.title-bar {
  div {
    display: inline-block;
    vertical-align: top;
  }
  .icon {
    padding: 5px;
    margin-right: 10px;
    background-color: #685e7a;
    border-radius: 5%;
    display: inline-block;
    span {
      display: block;
      width: 22px;
      height: 23px;
      line-height: 23px;
      text-align: center;
      font-size: 16px;
      color: white;
    }
    img {
      height: 23px;
      width: 23px;
    }
  }
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 10px;
  }
}

/* Legacy code. Mostly used in admin area */
$success: #79e897;
$primary: #2f5271;
$default: #e2e6ed;
$border: #c8c7cc;

.help-block {
  color: #a94442;
  margin-bottom: 0;
}

.warning-block {
  color: #f0ad4e;
  margin-bottom: 0;
  display: inline-block;
}

.btn-group {
  margin-bottom: 10px;
}

.form-control {
  box-shadow: none;
  border-color: $border;
  background-color: transparent;
  z-index: initial;

  &:focus {
    box-shadow: none;
    border-color: $border;
    outline: none;
  }
  &.active {
    outline: none;
    border-color: $border;
  }
}

.glyphicon-search {
  position: absolute;
  top: 10px;
  right: 24px;
  color: #333333;
}

.btn {
  border-color: $border;
  &.btn-default {
    background-color: #f6f7fb;
    &.active {
      background-color: white;
      box-shadow: none;
      outline: none;
      border-color: $border;
    }
    &:hover {
      border-color: $border;
    }
    &:focus {
      outline: none;
      border-color: $border;
    }
  }
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: white;
  color: black;
}

a.amcharts-amexport-label {
  color: black;
}

// li.amcharts-amexport-item.amcharts-amexport-item-level-0.amcharts-amexport-item-blank {
//   background-color: #f2f0ed;
// }

.main-button-purple {
  width: 200px;
  height: 56px;
  border-radius: 4px;
  border: none;
  background-color: #61566e;
  box-shadow: 0 1px 3px 0 rgba(140, 140, 140, 0.3);
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  line-height: 56px;
  outline: none;
  transition: background-color 0.2s ease-in-out;

  &.disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }

  &:not(.disabled):active,
  &:not(.disabled):hover {
    background-color: #514759;
    transition: background-color 0.2s ease-in-out;
  }
}

.main-button-green {
  width: 200px;
  height: 56px;
  border-radius: 4px;
  border: none;
  background-color: #578a6b;
  box-shadow: 0 1px 3px 0 rgba(140, 140, 140, 0.3);
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  line-height: 56px;
  outline: none;
  transition: background-color 0.2s ease-in-out;

  &.disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }

  &:not(.disabled):active,
  &:not(.disabled):hover {
    background-color: #376e56;
    transition: background-color 0.2s ease-in-out;
  }
}

.main-button-round {
  height: 38px;
  padding: 0 16px 0 16px;
  border-radius: 19px;
  -moz-border-radius: 19px;
  -webkit-border-radius: 19px;
  border: none;
  background-color: #578a6b;
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  display: inline-block;
  line-height: 38px;
  outline: none;
  transition: background-color 0.2s ease-in-out;

  &.disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
}

.page-title-container {
  margin-bottom: 24px;
  .page-title {
    height: 32px;
    position: relative;

    .action-container {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;

      button {
        flex: 1 0 auto;
      }
    }

    .title {
      position: absolute;
      left: 0;
      top: 0;
      div {
        margin-left: 15px;
        display: inline-block;
      }
    }
  }
}

.top-button-title {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .top-button-title-left {
    align-items: flex-start;
  }

  .top-button-title-right {
    align-items: flex-end;
    flex-direction: row;
    display: flex;
  }
}

.navigation-buttons-container {
  border-bottom: 1px solid #333333;
  width: 100%;
  margin-bottom: 32px;

  .navigation-button {
    padding-left: 32px;
    padding-right: 32px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    text-align: center;
    color: #333333;
    position: relative;

    .dropdown {
      display: inline-flex;
      align-items: center;

      h1 {
        font-size: 18px;
        letter-spacing: 0.5px;
      }
    }

    &:hover {
      .submenu {
        display: flex;
      }
    }
    .submenu {
      z-index: 2;
      width: 100%;
      display: none;
      position: absolute;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5), inset 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: #3c3446;
      flex-direction: column;
      padding: 7px 32px 7px 32px;

      button {
        width: 100%;
        border: none;
        background-color: transparent;
        color: white;
        font-size: 13px;
        letter-spacing: 0.6px;
        margin: 5px 0 5px 0;
        text-align: left;
        outline: none;

        &.active {
          font-weight: 900;
        }
      }
    }

    .navigation-accent {
      margin-top: 12px;
      height: 4px;
      background-color: transparent;
      transition: all 0.5s ease-in-out;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    .dropdown {
      h1 {
        font-weight: bold;
      }
    }

    .navigation-accent {
      background-color: #61566e;
    }
  }
}

.search-bar-wrapper {
  color: #333;
  height: 38px;
  width: 150px;
  border-radius: 18px;
  margin-left: auto;
  margin-right: 0;
  background-color: white;
  border: solid 1px #f2f0ed;

  .search-bar {
    border: none;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    height: 100%;
    width: 115px;
    padding-left: 20px;
    outline: none;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  mat-icon {
    vertical-align: middle;
    font-size: 18px;
    height: auto;
    width: auto;
  }
}

.content {
  margin: 20px 0 20px 0;
  position: relative;
  .location {
    border: none;
    box-shadow: none;
    border-radius: 0;
    color: black;
    background-color: #e2e6ed;
    &:focus {
      box-shadow: none;
    }
  }

  .input-group {
    .btn {
      height: 34px;
    }
  }

  .btn {
    border-radius: 0;
    border: none;
    background-color: $default;
    &.btn-default {
      background-color: $default;
      &:hover {
        background-color: darken($default, 10%);
      }
    }
    &.btn-success {
      background-color: $success;
      color: black;
      &:hover {
        background-color: darken($success, 10%);
      }
    }
    &.btn-primary {
      background-color: $primary;
      color: white;
      font-weight: bold;
      &:hover {
        background-color: darken($primary, 10%);
      }
    }
  }
}

.list {
  div[class^="col"]:hover {
    background-color: darken($default, 10%);
  }
}

[hidden] {
  display: none !important;
}

.color-picker {
  margin-top: 10px;
  input {
    width: 80%;
    display: inline;
  }
  .glyphicon {
    cursor: pointer;
    color: #96a8b8;
    &:hover {
      color: #e2e6ed;
    }
  }
}

.position-absolute-right {
  position: absolute;
  right: 0;
}

.input-field {
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #c8c7cc;
  padding: 8px;
  outline: none;

  &.multiline-text {
    resize: none;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: 15px;
    color: #c8c7cc;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 15px;
    color: #c8c7cc;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 15px;
    color: #c8c7cc;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 15px;
    color: #c8c7cc;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 15px;
    color: #c8c7cc;
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    font-size: 15px;
    color: #c8c7cc;
  }
}

/*
* Information header and text styles.
*/
.info-type-name {
  font-size: 13px;
  text-align: left;
  color: #8f8e94;
  text-transform: uppercase;
  margin-top: 16px;
}

.remove-link-styling {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.room-text {
  font-size: 15px;
  color: #333333;
}

.info-text {
  font-size: 15px;
  text-align: left;
  color: #333333;
}

.info-text-mid {
  font-size: 15px;
  text-align: center;
  color: #578a6b;
  float: left;
  width: 50%;
}

.text-center {
  text-align: center;
}

.text-italic {
  font-style: italic;
}

.info-hr {
  background-color: #c8c7cc;
  margin: 0;
  margin-top: 32px;
  padding: 0;
}

.info-section-title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: #333333;
  padding-top: 24px;
}

.info-not-refunded {
  font-size: 15px;
  color: #578a6b;
  margin-left: 10%;
  margin-right: 10%;
}

.info-refunded {
  font-size: 15px;
  color: #d7263c;
  margin-left: 10%;
  margin-right: 10%;
}

.hotel-check-information {
  flex: 1 0 auto;
  display: flex;

  .check-time {
    font-size: 13px;
    color: #333333;
  }

  .check-out-container {
    padding-left: 35px;
  }

  .check-arrow {
    padding-top: 28px;
    padding-left: 24px;
  }
}
/*  The default setting of the checkbox
*/
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: rgb(97, 86, 110);
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background-color: rgb(97, 86, 110);
}
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgb(97, 86, 110);
}
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgb(97, 86, 110);
}
.mat-checkbox-indeterminate .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: rgb(97, 86, 110);
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: rgb(97, 86, 110);
  background-color: rgb(97, 86, 110);
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgb(97, 86, 110);
}
/*
* Customize bootsrap form classes.
*/
.form-group {
  .checkbox-invitation-bookingSignal {
    font-family: "Lato-Bold";
    font-size: 14px;
    label {
      padding-left: 0px;
      font-weight: bold;
    }

    .mat-checkbox-layout {
      width: 100%;
    }
    .mat-checkbox-label {
      margin-right: 5px;
    }
    .mat-checkbox-inner-container {
      margin-right: 0px;
      margin-left: auto;
      width: 19px;
      height: 19px;
      object-fit: contain;
      border-radius: 4px;
    }
    .mat-checkbox-checked .mat-checkbox-background {
      background-color: #61566e;
    }
  }
  &.error {
    label {
      color: #d7263c;
    }

    .form-control {
      border-color: #030303;
    }
  }

  .datepicker {
    display: flex;
    .mat-form-field-appearance-outline .mat-form-field-flex {
      position: static;
    }
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0em 0 0em 0;
      justify-content: center;
      position: static;
    }
    .mat-form-field {
      align-content: flex-start;
      font-size: 13px;
      max-width: 123px;
      max-height: 36px;
    }
    .mat-form-field-outline {
      max-height: 36px;
    }
  }

  &.large-margin {
    margin-bottom: 24px;
  }

  label {
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: normal;
    margin-bottom: 4px;

    &.optional {
      font-size: 12px;
      color: #333333;
      font-weight: 100;
    }
  }

  .form-control {
    box-shadow: none;
    border-color: $border;
    background-color: transparent;
    z-index: initial;

    &.wrap-content {
      width: auto;
    }

    &:focus {
      box-shadow: none;
      border-color: $border;
      outline: none;
    }
    &.active {
      outline: none;
      border-color: $border;
    }
  }

  &.disabled {
    .form-control {
      border-color: #e1e1e1;
      color: #e1e1e1;
    }

    label {
      color: #e1e1e1;
    }
  }

  .upload-picture {
    border: none;
    background: transparent;
    text-decoration: underline;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #333333;
    margin: 0;
    padding: 0;
    width: 80px;
  }

  .image-form {
    display: flex;
    align-items: center;

    .img-container {
      border: 1px dashed rgba(0, 0, 0, 0.2);
      width: 128px;
      height: 128px;
      text-align: center;

      span {
        line-height: 128px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .img-action-container {
      padding-left: 8px;

      .image-delete-container {
        margin: 0;
      }
    }
  }

  .image-delete-container {
    margin-top: 13px;
    margin-bottom: 27px;
    margin-left: 12px;

    .image-name {
      font-size: 13px;
      font-weight: bold;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
      outline: none;
    }

    .image-delete {
      color: red;
      font-size: 14px;
      margin-left: 4px;
      font-weight: bold;
      border: none;
      background-color: transparent;
      outline: none;
    }
  }
}

/*
* General page top bar
*/

.top-bar {
  z-index: 90;
  position: fixed;
  width: 100%;
  top: 0;
  height: 72px;
  background-color: #3c3446;
  overflow: hidden;

  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.34);

  .brand {
    display: flex;
    margin-left: calc(5px + 1%);
    margin-top: 12px;

    img {
      width: 50px;
      height: 50px;
    }

    h1 {
      font-family: "Lato-Light";
      color: white;
      font-size: 15px;
      margin: 0;
      margin-left: 8px;
      line-height: 38px;
      letter-spacing: 1.5px;
    }
  }
}

/* genral page top bar end */

.button-add-green {
  display: flex;
  border: 0;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  outline: none;

  &:hover .circle {
    background-color: #376e56;
    transition: 0.1s ease-in-out;
  }

  .circle {
    background-color: #578a6b;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s ease-in-out;

    mat-icon {
      font-size: 24px;
      width: auto;
      height: auto;
    }
  }

  h2 {
    margin-left: 8px;
    font-size: 16px;
    color: #333333;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.custom-dialog-container {
  .mat-dialog-container {
    border-radius: 20px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    padding: 0px !important;
  }
}

.dialog-container {
  width: 290px;
  position: relative;

  h1 {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin: 0;
    padding-top: 8px;
    padding-bottom: 16px;
  }

  h2 {
    font-size: 16px;
    text-align: left;
    margin: 0;
    padding-top: 16px;
    padding-bottom: 8px;
  }

  .button-cancel {
    position: absolute;
    right: 0;
    top: 0;
  }

  .dialog-actions {
    margin-bottom: 0px;
    margin-top: 32px;

    button {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .mat-spinner {
    margin: 32px auto 0px auto;
    animation: fadein 0.5s;
  }

  .content-container {
    animation: fadein 0.5s;
  }
}

.tour-container {
  width: 430px;

  h4 {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.72;
    letter-spacing: 0.6px;
    color: #ffffff;
  }

  p {
    margin-top: 8px;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}

.help-mark {
  width: 16px;
  height: 16px;
}

.automessage-dialog-container .mat-dialog-container {
  border-radius: 20px;
  margin-top: -300px;
}

.automessage-type-dialog-container .mat-dialog-container {
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
  margin-top: -100px;
  width: 541px;
}

.ellipsis {
  min-width: 0;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.pointer {
  cursor: pointer;
}

.full-screen-modal .mat-dialog-container {
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
}

.direct-bookings-cancel-confirmation-dialog {
  position: absolute !important;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%;
}

@media only screen and (max-width: 1152px) {
  .direct-bookings-cancel-confirmation-dialog {
    width: 100%;
    height: 80%;
  }
}
