@use "@angular/material" as mat;
@include mat-core();
/* ======== Angular material custom themes ======== */

$ag-purple: (
  50: #eae7ee,
  100: #cac2d4,
  200: #a79ab7,
  300: #84729a,
  400: #695384,
  500: #4f356e,
  600: #483066,
  700: #3f285b,
  800: #362251,
  900: #26163f,
  A100: #ac7eff,
  A200: #8c4bff,
  A400: #6b18ff,
  A700: #5b00fd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-agpurewhite: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-agdark: (
  50: #e4e4e4,
  100: #bdbdbd,
  200: #919191,
  300: #646464,
  400: #434343,
  500: #222222,
  600: #1e1e1e,
  700: #191919,
  800: #141414,
  900: #0c0c0c,
  A100: #e76c6c,
  A200: #e04040,
  A400: #ec0000,
  A700: #d30000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-aglightpurple: (
  50: #fcfafe,
  100: #f7f3fb,
  200: #f2ebf9,
  300: #ece3f7,
  400: #e8ddf5,
  500: #e4d7f3,
  600: #e1d3f1,
  700: #ddcdef,
  800: #d9c7ed,
  900: #d1beea,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-aglightgold: (
  50: #fffefb,
  100: #fffef5,
  200: #fffdef,
  300: #fffce8,
  400: #fffbe3,
  500: #fffade,
  600: #fff9da,
  700: #fff9d5,
  800: #fff8d1,
  900: #fff6c8,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-aggold: (
  50: #fcf7ec,
  100: #f7eccf,
  200: #f2dfaf,
  300: #ecd28e,
  400: #e8c876,
  500: #e4be5e,
  600: #e1b856,
  700: #ddaf4c,
  800: #d9a742,
  900: #d19931,
  A100: #ffffff,
  A200: #fff5e3,
  A400: #ffe2b0,
  A700: #ffd996,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-agdarkgrey: (
  50: #ebebeb,
  100: #cecece,
  200: #aeaeae,
  300: #8d8d8d,
  400: #747474,
  500: #5c5c5c,
  600: #545454,
  700: #4a4a4a,
  800: #414141,
  900: #303030,
  A100: #f39191,
  A200: #ee6363,
  A400: #ff1f1f,
  A700: #ff0505,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-agmediumgrey: (
  50: #f4f4f4,
  100: #e4e4e4,
  200: #d3d3d3,
  300: #c1c1c1,
  400: #b3b3b3,
  500: #a6a6a6,
  600: #9e9e9e,
  700: #959595,
  800: #8b8b8b,
  900: #7b7b7b,
  A100: #ffffff,
  A200: #fad6d6,
  A400: #ff9e9e,
  A700: #ff8585,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-aglightgrey: (
  50: #fbfbfb,
  100: #f5f5f5,
  200: #efefef,
  300: #e9e9e9,
  400: #e4e4e4,
  500: #dfdfdf,
  600: #dbdbdb,
  700: #d7d7d7,
  800: #d2d2d2,
  900: #cacaca,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-agcream: (
  50: #fdfdfd,
  100: #fbfbfb,
  200: #f9f9f9,
  300: #f6f6f6,
  400: #f4f4f4,
  500: #f2f2f2,
  600: #f0f0f0,
  700: #eeeeee,
  800: #ececec,
  900: #e8e8e8,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-agcream: (
  50: #fdfdfd,
  100: #fbfbfb,
  200: #f9f9f9,
  300: #f6f6f6,
  400: #f4f4f4,
  500: #f2f2f2,
  600: #f0f0f0,
  700: #eeeeee,
  800: #ececec,
  900: #e8e8e8,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-agbackgroundlightgreen: (
  50: #eef8f5,
  100: #d4efe6,
  200: #b7e4d5,
  300: #9ad9c4,
  400: #84d0b8,
  500: #6ec8ab,
  600: #66c2a4,
  700: #5bbb9a,
  800: #51b491,
  900: #3fa780,
  A100: #f9fffd,
  A200: #c6ffe9,
  A400: #93ffd5,
  A700: #7affcc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$ag-primary-purple: mat.define-palette($ag-purple);
$ag-primary-dark: mat.define-palette($md-agdark);
$ag-primary-purewhite: mat.define-palette($md-agpurewhite);
$ag-accent-lightpurple: mat.define-palette($md-aglightpurple);
$ag-accent-lightgold: mat.define-palette($md-aglightgold);
$ag-accent-gold: mat.define-palette($md-aggold);
$ag-warn-darkgrey: mat.define-palette($md-agdarkgrey);
$ag-warn-mediumgrey: mat.define-palette($md-agmediumgrey);
$ag-warn-lightgrey: mat.define-palette($md-aglightgrey);
$ag-warn-cream: mat.define-palette($md-agcream);
$ag-background-lightgreen: mat.define-palette($md-agbackgroundlightgreen);

$ag-main-theme: mat-light-theme(
  (
    color: (
      primary: $ag-primary-purple,
      accent: $ag-accent-lightpurple,
      warn: $ag-warn-darkgrey,
    ),
    typography: mat-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($ag-main-theme);

.ag-dark-theme {
  $ag-main-dark-theme: mat-light-theme(
    (
      color: (
        primary: $ag-primary-dark,
        accent: $ag-accent-lightgold,
        warn: $ag-warn-mediumgrey,
      ),
    )
  );
  @include mat.all-component-themes($ag-main-dark-theme);
}

.ag-purewhite-theme {
  $ag-main-purewhite-theme: mat-light-theme(
    (
      color: (
        primary: $ag-primary-purewhite,
        accent: $ag-accent-gold,
        warn: $ag-warn-lightgrey,
      ),
    )
  );
  @include mat.all-component-themes($ag-main-purewhite-theme);
}

/* Themes specifc for <app-data-display-icon>**/
.ag-green-spinner-theme {
  $ag-green-spinner-theme: mat-light-theme(
    (
      color: (
        primary: $ag-primary-purewhite,
        accent: $ag-background-lightgreen,
        warn: $ag-background-lightgreen,
      ),
    )
  );
  @include mat.all-component-themes($ag-green-spinner-theme);
}

.ag-white-spinner-theme {
  $ag-white-spinner-theme: mat-light-theme(
    (
      color: (
        primary: $ag-primary-purple,
        accent: $ag-warn-cream,
        warn: $ag-warn-cream,
      ),
    )
  );
  @include mat.all-component-themes($ag-white-spinner-theme);
}

.ag-black-spinner-theme {
  $ag-black-spinner-theme: mat-light-theme(
    (
      color: (
        primary: $ag-primary-dark,
        accent: $ag-warn-cream,
        warn: $ag-warn-cream,
      ),
    )
  );
  @include mat.all-component-themes($ag-black-spinner-theme);
}
