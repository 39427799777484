/* 
 * This file contains Angular Material theme modifications 
 */

@import "./styles/colors";
@import "_agcolours";

$color-black: #333;
$color-purple: #61566e;
$color-red: #d7263c;
$color-green: #578a6b;
$color-grey: #f2f0ed;
$color-yellow: #e4be5e;

$text-background-color: #8f8e94;
$text-white-background-color: #c8c7cc;

html,
body {
  color: $color-dark;
  background-color: $ag-white-background;
}

h1 {
  color: $color-primary;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
  height: 5px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: $color-secondary; //#f1f1f1;
  border-radius: 3px;
  border: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $color-border;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

// --------------------------------------------
// AG Construction site
// --------------------------------------------

.mat-button,
.mat-raised-button {
  .mat-button-ripple {
    z-index: 2;
  }

  &.transparent {
    width: 120px;
    height: 28px;
    border-radius: 19px;
    border: solid 1px #f2eee7;
    color: #f2eee7;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    outline: none;

    .mat-button-wrapper {
      color: #ffffff;
    }

    mat-spinner {
      margin-left: auto;
      margin-right: auto;

      circle {
        stroke: rgba(255, 255, 255, 0.5);
      }
    }

    &[disabled] {
      .mat-button-wrapper {
        color: rgba(255, 255, 255, 0.3);
      }

      cursor: not-allowed;
    }
  }

  &.yellow {
    width: 149px;
    height: 50px;
    border-radius: 25px;
    border: none;
    background-color: #e4be5e;
    box-shadow: 0 1px 3px 0 rgba(140, 140, 140, 0.3);
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    line-height: 50px;
    outline: none;

    .mat-button-wrapper {
      color: #ffffff;
    }

    mat-spinner {
      margin-left: auto;
      margin-right: auto;

      circle {
        stroke: rgba(255, 255, 255, 0.5);
      }
    }

    &[disabled] {
      .mat-button-wrapper {
        color: rgba(255, 255, 255, 0.3);
      }

      cursor: not-allowed;
    }
  }

  &.purple {
    width: 200px;
    height: 56px;
    border-radius: 4px;
    border: none;
    background-color: #61566e;
    box-shadow: 0 1px 3px 0 rgba(140, 140, 140, 0.3);
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 56px;
    outline: none;
    transition: background-color 0.1s ease-in-out;

    .mat-button-wrapper {
      color: white;
    }

    mat-spinner {
      margin-left: auto;
      margin-right: auto;

      circle {
        stroke: rgba(255, 255, 255, 0.5);
      }
    }

    &[disabled] {
      .mat-button-wrapper {
        color: rgba(255, 255, 255, 0.3);
      }

      cursor: not-allowed;
    }
  }

  &.green {
    width: 200px;
    height: 56px;
    border-radius: 4px;
    border: none;
    background-color: $color-green;
    box-shadow: 0 1px 3px 0 rgba(140, 140, 140, 0.3);
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 56px;
    outline: none;
    transition: background-color 0.1s ease-in-out;

    .mat-button-wrapper {
      color: white;
    }

    mat-spinner {
      margin-left: auto;
      margin-right: auto;

      circle {
        stroke: rgba(255, 255, 255, 0.5);
      }
    }

    &[disabled] {
      .mat-button-wrapper {
        color: rgba(255, 255, 255, 0.3);
      }

      cursor: not-allowed;
    }
  }
}

.mat-button-focus-overlay {
  background-color: transparent;
}

.mat-icon-button {
  &.purple-big {
    height: 32px !important;
    width: 32px !important;
    line-height: 32px !important;
    display: block !important;

    background-color: #61566e;
    box-shadow: 0 1px 3px 0 rgba(140, 140, 140, 0.3);
    color: #ffffff;
    outline: none;
    transition: background-color 0.1s ease-in-out;

    .mat-icon {
      font-size: 20px !important;
      height: 32px !important;
      width: 32px !important;
      line-height: 32px !important;
      display: block !important;
    }

    .mat-button-wrapper {
      color: white;
    }

    &.disabled {
      color: rgba(255, 255, 255, 0.3);
      cursor: not-allowed;
    }

    &:not(.disabled):active,
    &:not(.disabled):hover {
      background-color: #514759;
      transition: background-color 0.1s ease-in-out;
    }
  }
}

.mat-dialog-container {
  overflow: unset !important;
  border-radius: 20px !important;
  background-color: #ffffff;
}

/* 
 * Material Select 
 */
.mat-select {
  height: 36px;
  background-color: white;
  border-radius: 4px;
  border: solid 1px #c8c7cc;
  line-height: 36px;
  padding-left: 8px;
}

.mat-option .mat-active {
  background-color: rgba(97, 86, 110, 0.2);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: rgba(97, 86, 110, 1);
  color: white;
}

.mat-option:focus:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled) {
  background-color: rgba(97, 86, 110, 0.2);
}
/* Material Select end */

/* 
 * Toggle buttons circular 
 */

.mat-button-toggle-group.round {
  box-shadow: none !important;

  .mat-button-toggle.cdk-focused {
    background-color: white;
  }

  .mat-button-toggle.mat-button-focus-overlay {
    background-color: white;
  }

  .mat-button-toggle.mat-button-toggle-checked {
    background-color: #e4be5e;
    color: white;
  }

  .mat-button-toggle {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-left: 8px;

    .mat-button-toggle-label {
      padding: 0;
      line-height: 32px;
      display: block;
      text-align: center;

      .mat-button-toggle-label-content {
        padding: 0;
      }
    }
  }
}
/* Toggle buttons circular end */

/* Material slide toggle */
.mat-slide-toggle {
  &.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      background-color: rgba(228, 190, 94, 0.5);
      .mat-slide-toggle-thumb {
        background-color: #e4be5e;
      }

      .mat-ripple-element {
        background-color: rgba(228, 190, 94, 0.3);
      }
    }
  }

  &.green {
    &.mat-checked:not(.mat-disabled) {
      .mat-slide-toggle-bar {
        background-color: #578a6b;
        .mat-slide-toggle-thumb {
          background-color: white;
        }

        .mat-ripple-element {
          background-color: rgba(87, 138, 107, 0.3);
        }
      }
    }

    .mat-slide-toggle-bar {
      background-color: #d7263c;
      .mat-slide-toggle-thumb {
        background-color: white;
      }

      .mat-ripple-element {
        background-color: rgba(215, 38, 60, 0.3);
      }
    }
  }
}
/* Material slide toggle end */

/* Form */

.white-form {
  .mat-form-field {
    &.mat-focused {
      .mat-form-field-label {
        padding-left: 10px;
        color: #dbb956;
      }
    }

    .mat-form-field-label {
      padding-left: 10px;
      color: white;
    }

    .mat-input-element {
      padding-left: 10px;
      caret-color: #dbb956;
    }

    .mat-form-field-underline {
      background-color: rgba(255, 255, 255, 0.42);

      .mat-form-field-ripple {
        background-color: #dbb956;
      }
    }

    &.mat-form-field-invalid {
      .mat-form-field-label {
        color: red;
      }

      .mat-input-element {
        padding-left: 10px;
        caret-color: red;
      }

      .mat-form-field-ripple {
        background-color: red;
      }
    }
  }
}

/* Form end */

mat-icon {
  &.white-icon {
    color: white;
  }

  &.green {
    color: $color-green;
  }

  &.yellow {
    color: $color-yellow;
  }

  &.red {
    color: $color-red;
  }

  &.purple {
    color: $color-purple;
  }
}

mat-spinner {
  &.grey {
    circle {
      stroke: rgba(255, 255, 255, 0.5);
    }
  }

  &.purple {
    circle {
      stroke: #61566e;
    }
  }
}

.mat-paginator {
  background-color: transparent;

  .mat-form-field {
    &.mat-focused {
      .mat-select-arrow {
        color: #61576d;
      }
    }
  }

  .mat-select {
    height: auto;
    line-height: inherit;
    background-color: transparent;
    border: none;
    padding-left: 8px;
  }

  .mat-input-ripple {
    background-color: #61576d;
  }

  .mat-paginator-navigation-next {
    @extend .mat-paginator-navigation-previous;
  }

  .mat-paginator-navigation-previous {
    height: 32px;
    width: 32px;
    line-height: 32px;
    display: block;

    background-color: transparent;
    box-shadow: none;
    color: #333;
    outline: none;
    transition: background-color 0.1s ease-in-out;
    fill: #333;

    .mat-icon {
      font-size: 20px;
      height: 32px;
      width: 32px;
      line-height: 32px;
      display: block;
    }

    .mat-button-wrapper {
      color: #333;
    }

    &[disabled] {
      cursor: not-allowed;

      .mat-button-wrapper {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    &:not(.disabled):active,
    &:not(.disabled):hover {
      background-color: transparent;
      transition: background-color 0.1s ease-in-out;
    }
  }
}

mat-tab-header {
  border-bottom: 1px solid #333333 !important;

  .mat-tab-label:not(.mat-tab-disabled):focus {
    background-color: transparent !important;
  }

  .mat-tab-label {
    opacity: 1;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;

    &.mat-tab-label-active {
      font-family: "Lato-Bold";
    }
  }

  .mat-ink-bar {
    height: 4px !important;
    background-color: #514759 !important;
  }
}

.mat-menu-panel {
  .mat-menu-item {
    &:hover:not([disabled]) {
      background-color: rgba(97, 86, 110, 0.2);
    }
  }
}

/* nxg-joyride tour */
.backdrop-container {
  animation: fadein 0.5s;

  .backdrop-bottom,
  .backdrop-top,
  .backdrop-right,
  .backdrop-left {
    background-color: rgba(0, 0, 0, 0.5) !important;
    transition: all 1s ease-in-out;
  }
}

joyride-step {
  color: white;

  .joyride-step__container {
    background-color: $color-green;
    color: white;

    .joyride-step__close {
      line {
        stroke: white;
      }
    }

    .joyride-step__footer {
      .joyride-step__prev-button {
        button {
          border: none !important;
          background-color: transparent !important;
          text-transform: capitalize;
          text-decoration: underline;
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.4px;
          text-align: center;

          &:hover {
            color: white !important;
          }
        }
      }

      .joyride-step__next-button,
      .joyride-step__done-button {
        button {
          background-color: rgba(0, 0, 0, 0.2) !important;
          text-transform: capitalize;
          border-radius: 4px;

          &:hover {
            color: white !important;
            border-color: transparent !important;
          }
        }
      }
    }
  }

  .joyride-arrow__left {
    border-right: 11px solid $color-green;
  }

  .joyride-arrow__right {
    border-left: 11px solid $color-green;
  }

  .joyride-arrow__top {
    border-bottom: 11px solid $color-green;
  }

  .joyride-arrow__bottom {
    border-top: 11px solid $color-green;
  }
}

/* 
* Production build does not order how the css files are bundled, so angular material css files
* override global styles.
*/

button[mat-button].primary {
  width: 160px;
  height: 50px;
  border-radius: 25px;
  background-color: #61566e;
  font-size: 14px;
  color: #f2eee7;

  &:disabled {
    background-color: #61566e;
    color: #f2eee7;
    opacity: 0.5;
  }
}

button[mat-button] {
  &.danger {
    background-color: #d7263c;
    color: #ffffff;
  }

  &.success {
    background-color: #578a6b;
    color: #ffffff;
  }
}

button[mat-button] {
  &.medium {
    border-radius: 1.32em;
  }
}

button[mat-button].edit-button {
  height: 28px;
  border-radius: 19px;
  background-color: #61566e;
  font-size: 12px;
  color: #f2eee7;
  line-height: normal;
}

.ag-dialog-header {
  position: relative;

  h2 {
    font-size: 18px;
    font-weight: bold;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
  }
}

div[mat-dialog-actions].ag-dialog-actions {
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
}

.expand-more {
  height: 28px;
  width: 28px;
  border-radius: 14px;
  background-color: #61566e;
  display: flex;
  justify-content: center;
  cursor: pointer;

  .color-convert {
    filter: invert(1);
    transform: rotate(0deg);
    transition: 150ms;
  }
}

.dropdown-menu {
  position: absolute;
  height: auto;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
  top: 100%;
  right: 0;
  left: auto;
  cursor: pointer;

  .option {
    padding: 8px 8px 6px 8px;
  }
  .delete {
    color: #d7263c;
  }
}
.open {
  position: relative;
  .expand-more {
    background-color: #61566e;
    .color-convert {
      filter: invert(1);
      transform: rotate(-90deg);
      transition: 150ms;
    }
  }
}

.deactivated {
  opacity: 0.5;
}

/* AgUI theme BEGIN */

small > img {
  margin-right: 6px;
}

p.ag-form-segment {
  font-weight: bold;
  margin-bottom: 8px;
}

.ag-form-row {
  gap: 16px;

  &:not(:last-of-type) {
    margin-bottom: 24px !important;
  }
}

.ag-form-control {
  padding: 8px 12px 8px 12px;
  border-radius: 12px;
  border: solid 1px #c8c7cc;
  display: inline-flex;
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  position: relative;
  &.has-errors {
    border: solid 2px #d7263c;
    padding: 7px 11px 7px 11px;
  }

  &.focussed {
    border: solid 2px #61566e;
    padding: 7px 11px 7px 11px;
  }

  textarea,
  input {
    transition: 0.2s ease all;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: $color-white;
  }

  textarea:focus ~ label,
  input:focus ~ label {
    color: #61566e;
    font-weight: bolder;
  }

  textarea:not(:placeholder-shown) ~ label input:not(:placeholder-shown) ~ label {
    color: #333333;
  }

  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label,
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    font-size: 10px;
    top: 0px;
    opacity: 1;
    transition: 0.2s ease all;
  }

  textarea:focus,
  textarea:not(:placeholder-shown),
  input:focus,
  input:not(:placeholder-shown) {
    position: relative;
    top: 7px;
  }

  label {
    position: absolute;
    pointer-events: none;
    font-size: 13px;
    left: 2px;
    top: 6px;
    transition: 0.2s ease all;
    color: #ccc;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 0;
    font-weight: normal;
    margin: 0;
    display: inline;
  }

  .ag-value {
    position: relative;
    width: 100%;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.disabled,
  .disabled {
    opacity: 0.5;

    input:disabled {
      background-color: white;
    }
  }
}

.cdk-overlay-dark-backdrop {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.4);
}

/* AgUI theme END*/
