/* COLORS */
$color-primary: #61566e;
$color-accent: #3c3446;
$color-secondary: #f1f1f1;
$color-success: #578a6b;
$color-info: #6c757d;
$color-warning: #e4be5e;
$color-danger: #d7263c;
$color-light: #fdfdfd;
$color-dark: #333333;
$color-white: #ffffff;
$color-white-background: #fafafa;
$color-border: #c8c7cc;
