/* Main colours**/
$ag-colour-purple: #4f356e;
$ag-colour-dark: #222222;
$ag-colour-purewhite: #ffffff;

$ag-colour-lightpurple: #e4d7f3;
$ag-colour-lightgold: #41394d;
$ag-colour-gold: #e4be5e;

$ag-colour-darkgrey: #5c5c5c;
$ag-colour-mediumgrey: #a6a6a6;
$ag-colour-lightgrey: #dfdfdf;

$ag-colour-cream: #f2f2f2;
$ag-white-background: #fafafa;

/* Secondary **/
$ag-colour-lightpurple: #e4d7f3;
$ag-colour-lightgold: #fffade;
$ag-colour-skyblue: #6e89da;
$ag-colour-pink: #cb9def;

/* UI and Data **/
$ag-colour-blue: #70a8ff;
$ag-colour-darkblue: #385480;
$ag-colour-lightblue: #f0f6ff;

$ag-colour-green: #4aba96;
$ag-colour-darkgreen: #2c6e58;
$ag-colour-lightgreen: #eafbee;

$ag-colour-yellow: #ffd67a;
$ag-colour-darkyellow: #806b3d;
$ag-colour-lightyellow: #fff0ca;

$ag-colour-red: #e55e5e;
$ag-colour-darkred: #993f3f;
$ag-colour-lightred: #fff2f0;

/* Conversion colours **/
$ag-colour-0-9: #e65e5e;
$ag-colour-10-19: #f09e9e;
$ag-colour-20-29: #ffd67a;
$ag-colour-30-39: #ffde95;
$ag-colour-40-49: #ffe6af;
$ag-colour-50-59: #92d6c0;
$ag-colour-60-69: #6ec8ab;
$ag-colour-70-79: #4aba96;
$ag-colour-80-89: #568b79;
$ag-colour-90-100: #2c6e58;
$ag-colour-100: #70a8ff;

/* Gradient **/
$ag-gradient: linear-gradient(to top right, #4f356e, #625da3, #6973be, #6e89da, #9690e4, #ba96eb, #cb9def);

// To use gradient for text add:
/*
    Either use background or background image
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
**/
